import React, { Component } from 'react';
import { Form,Col,Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    saveStep1Data 
} from '../actions/paynowActions';

class PaynowStep1 extends Component {
    constructor(){
        super()
        this.state = {
            show: false,
        }
        this.handleShow = this.handleShow.bind(this);
    }
    handleShow(e) {
        e.preventDefault();
        this.setState({ show: true });
    }
    handleClose = () => {
        this.setState({ show: false });
    }
    onChange = (e) => {
        this.props.saveStep1Data(e.target.name, e.target.value)
    }
    render() { 
        const step1Data = this.props.paynow.step1Data
        return (  
            <React.Fragment>
                <Form
                    autocomplete="off"
                    noValidate 
                    validated={step1Data.validated}
                >
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Label className="font-bold">Enter the 10-digit account number</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="accountId"
                                value={step1Data.accountId}
                                onChange={this.onChange.bind(this)}
                                maxLength={10}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Account Number.
                            </Form.Control.Feedback>
                            <Form.Label>
                                <a href="/" className="secondary-font-color regular-font-size" onClick={this.handleShow}><u>Where can I find my account number?</u></a>
                            </Form.Label>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label className="font-bold">GWA Billing Zip Code (Enter your 5-digit zip code.)</Form.Label>
                            <Form.Control
                                required
                                autocomplete="off"
                                type="text"
                                name="zipcode"
                                className="valid"
                                value={step1Data.zipcode}
                                onChange={this.onChange.bind(this)}
                                maxlength="5"
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your zip code.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                </Form>
                <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="forgot-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="hint">
                            <img src={process.env.PUBLIC_URL + '/gwa-bill-preview.png'} alt="find-account-number"/>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paynow: state.paynow
});

export default connect(mapStateToProps, { 
    saveStep1Data,  
})(PaynowStep1);