import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Loading from "./loading";
import {
  Form,
  Col,
  Row,
  InputGroup,
  FormControl,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  saveOrderData,
  savePaymentData,
  validateVisaPayment,
} from "../actions/dashboardActions";
import { showMessage } from "../actions/popupMessageActions";
import _ from "lodash";
import $ from "jquery";
import * as moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import NumberFormat from "react-number-format";
class PayNowModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      showHint: false,
      isLoading: true,
      accountSummary: "",
      subtotal: "",
      showErrMsg: false,
      isPaymentProcessing: false,
      username: "",
      userFullName: "",
      expDateForm: "",
      cardDetails: {
        cardHolderName: "",
        cardNumber: "",
        expDate: new Date(),
        validExpDate: "",
        cvv: "",
        billingZipCode: "",
        confirmationEmail:
          this.props.dashboard.userAccountDetails.emailAddress === undefined
            ? this.props.email
            : this.props.dashboard.userAccountDetails.emailAddress,
      },
      isVisaChecked: "",
      isMasterCardChecked: "",
      usedCC: "",
    };
  }
  componentWillMount() {
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem("reload") === "true") {
        localStorage.removeItem("reload");
        window.location.reload();
      }
    });
    let username =
      localStorage.getItem("username") === "" ||
      localStorage.getItem("username") === undefined ||
      localStorage.getItem("username") === null
        ? this.props.adminUsername
        : localStorage.getItem("username");
    this.setState({
      userFullName:
        this.props.dashboard.userAccountDetails.fullName === undefined
          ? this.props.fullName
          : this.props.dashboard.userAccountDetails.fullName,
      username: username,
      mode: this.props.mode === undefined ? "" : this.props.mode,
    });
  }
  showModal = (e) => {
    let invalidAmounts = 0;
    _.map(this.props.dashboard.orderData.accountSummary, (item, index) => {
      if (parseFloat(item.amountToBePaid) <= 0.0) {
        invalidAmounts = invalidAmounts + 1;
      }
    });
    if (this.props.dashboard.orderData.subTotal == 0) {
      this.setState({ isVisaChecked: false, isMasterCardChecked: false });
      this.props.showMessage(
        "danger",
        "Please select an account and enter a valid amount to be paid!"
      );
    } else if (invalidAmounts > 0) {
      this.setState({ isVisaChecked: false, isMasterCardChecked: false });
      this.props.showMessage(
        "danger",
        "Payment Amount in one or more accounts must be greater than zero."
      );
    } else {
      this.setState({
        show: true,
        isVisaChecked: false,
        isMasterCardChecked: false,
      });
    }
  };
  hideModal = (e) => {
    try {
      e.preventDefault();
    } catch (ex) {}
    if (!this.state.isPaymentProcessing) {
      this.setState({ show: false });
    }
  };
  showCvvHint = (e) => {
    this.setState({ showHint: true });
  };
  hideCvvHint = (e) => {
    this.setState({ showHint: false });
  };
  inputOnChange = (e) => {
    if (e.target.name === "cardNumber") {
      let value = e.target.value.replace(/\ /g, "");
      value = value.replace(/\_/g, "");
      console.log("value", value);
      this.setState({
        ...this.state,
        cardDetails: {
          ...this.state.cardDetails,
          cardNumber: value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        cardDetails: {
          ...this.state.cardDetails,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleExpDate = (date) => {
    this.setState({
      ...this.state,
      expDateForm: date,
      cardDetails: {
        ...this.state.cardDetails,
        validExpDate: moment(date).format("MMYY"),
      },
    });
  };

  async savePayment(e) {
    
    this.setState({
      isPaymentProcessing: true,
    });
    e.preventDefault();
    const accountSummary = this.props.dashboard.orderData.accountSummary;
    const subtotal = this.props.dashboard.orderData.subTotal;
    const usedCC = this.getUsedCC();
    this.setState({ usedCC: usedCC }, async () => {
      let arrIsAmountValid = [];
      if (
        this.state.cardDetails.cardNumber.charAt(0) === "4" ||
        this.state.cardDetails.cardNumber.charAt(0) === "6"
      ) {
        // for(let count = 0; count < accountSummary.length; count++){
        //     if(accountSummary[count].checked){
        //         try{
        //             let result = await Promise.all([this.props.validateVisaPayment(accountSummary[count].accID, this.state.usedCC)]);
        //             let paymentAmount = result[0].data.result.data.PaymentAmount;
        //             let customerClass = result[0].data.result.data.CustomerClass;
        //             const totalPayment = parseInt(accountSummary[count].amountToBePaid) + parseInt(paymentAmount);

        //             if(totalPayment > 500 && !(customerClass === "RESID")){
        //                 accountSummary[count].validAmountToBePaid = false;
        //                 accountSummary[count].alreadyPaid = paymentAmount;
        //                 accountSummary[count].usedCC = usedCC;
        //                 arrIsAmountValid.push(false);
        //             }
        //             else{
        //                 accountSummary[count].validAmountToBePaid = true;
        //                 arrIsAmountValid.push(true);
        //             }
        //         }
        //         catch(error){
        //             let result = {
        //                 status: "serverFailed"
        //             };
        //             localStorage.setItem('accountSummary', JSON.stringify(this.state.accountSummary));
        //             localStorage.setItem('paymentResult', JSON.stringify(result));
        //             this.setState({
        //                 isPaymentProcessing: false
        //             })
        //             this.showPaymentResult();
        //         }
        //     }
        // }
        for (let count = 0; count < accountSummary.length; count++) {
          accountSummary[count].validAmountToBePaid = true;
        }
        arrIsAmountValid.push(true);
      } else {
        let postData = {};
        for (let count = 0; count < accountSummary.length; count++) {
          accountSummary[count].validAmountToBePaid = true;
        }
        arrIsAmountValid.push(true);
      }
      //sort accountSummary
      let sortedAccountSummary = [];
      //get all invalid amounts
      let allInvalidAmounts = [];
      for (let count = 0; count < accountSummary.length; count++) {
        if (!accountSummary[count].validAmountToBePaid) {
          allInvalidAmounts.push(accountSummary[count]);
        }
      }
      //get all checked rows
      let checkedRows = [];
      for (let count = 0; count < accountSummary.length; count++) {
        if (
          accountSummary[count].validAmountToBePaid &&
          accountSummary[count].checked
        ) {
          checkedRows.push(accountSummary[count]);
        }
      }
      //get all resid accounts
      let residAccts = [];
      for (let count = 0; count < accountSummary.length; count++) {
        if (
          accountSummary[count].className === "RESID" &&
          accountSummary[count].validAmountToBePaid &&
          !accountSummary[count].checked
        ) {
          residAccts.push(accountSummary[count]);
        }
      }
      //get all non-resid accounts
      let nonResidAccts = [];
      for (let count = 0; count < accountSummary.length; count++) {
        if (
          accountSummary[count].className != "RESID" &&
          accountSummary[count].validAmountToBePaid &&
          !accountSummary[count].checked
        ) {
          nonResidAccts.push(accountSummary[count]);
        }
      }
      //insert all invalid amounts
      for (let count = 0; count < accountSummary.length; count++) {
        for (let count1 = 0; count1 < allInvalidAmounts.length; count1++) {
          if (accountSummary[count].accID === allInvalidAmounts[count1].accID) {
            sortedAccountSummary.push(allInvalidAmounts[count1]);
            break;
          }
        }
      }
      //insert all checked rows
      for (let count = 0; count < accountSummary.length; count++) {
        for (let count1 = 0; count1 < checkedRows.length; count1++) {
          if (accountSummary[count].accID === checkedRows[count1].accID) {
            sortedAccountSummary.push(checkedRows[count1]);
            break;
          }
        }
      }
      //insert all resid accounts
      for (let count = 0; count < accountSummary.length; count++) {
        for (let count1 = 0; count1 < residAccts.length; count1++) {
          if (accountSummary[count].accID === residAccts[count1].accID) {
            sortedAccountSummary.push(residAccts[count1]);
            break;
          }
        }
      }

      //insert all non-resid accounts
      for (let count = 0; count < accountSummary.length; count++) {
        for (let count1 = 0; count1 < nonResidAccts.length; count1++) {
          if (accountSummary[count].accID === nonResidAccts[count1].accID) {
            sortedAccountSummary.push(nonResidAccts[count1]);
            break;
          }
        }
      }
      if (arrIsAmountValid.includes(false)) {
        const postData = {
          subTotal: parseFloat(Math.round(subtotal * 100) / 100).toFixed(2),
          accountSummary: sortedAccountSummary,
          isHasInvalid: true,
        };
        this.props.saveOrderData(postData);
        this.setState({
          show: false,
          isPaymentProcessing: false,
        });
      } else {
        const postData = {
          subTotal: parseFloat(Math.round(subtotal * 100) / 100).toFixed(2),
          accountSummary: sortedAccountSummary,
          isHasInvalid: false,
        };
        this.props.saveOrderData(postData);
        this.validUserInputs(subtotal, accountSummary);
      }
    });
  }
  getUsedCC = () => {
    const cardNumber = this.state.cardDetails.cardNumber;
    if (cardNumber.charAt(0) === "4") {
      return "visa";
    } else if (cardNumber.charAt(0) === "6") {
      return "discover";
    } else if (
      parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) > 50 &&
      parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) < 56
    ) {
      return "master";
    } else {
      return "invalid";
    }
  };
  validUserInputs(subtotal, accountSummary) {
    const usedCC = this.getUsedCC();
    if (usedCC === "invalid") {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage("danger", "Invalid Card Number Format!");
    } else if (this.state.isVisaChecked && usedCC != "visa") {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Visa Card Number!"
      );
    } else if (this.state.isMasterCardChecked && usedCC != "master") {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Mastercard Number!"
      );
    } else if (
      (this.state.cardDetails.cardNumber.length < 16 ||
        this.state.cardDetails.cardNumber.length > 16) &&
      usedCC === "visa"
    ) {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Visa Card Number!"
      );
    } else if (
      (this.state.cardDetails.cardNumber.length < 16 ||
        this.state.cardDetails.cardNumber.length > 16) &&
      usedCC === "master"
    ) {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Mastercard Number!"
      );
    } else if (
      (this.state.cardDetails.cardNumber.length < 16 ||
        this.state.cardDetails.cardNumber.length > 16) &&
      usedCC === "discover"
    ) {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Dicover Card Number!"
      );
    } 
    else if(this.state.cardDetails.billingZipCode=="" || this.state.cardDetails.billingZipCode.length!=5){
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage("danger", "Invalid Zip Code");
    }else if (this.state.cardDetails.confirmationEmail != "") {
      let email = String(this.state.cardDetails.confirmationEmail);
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.setState(
          {
            subtotal: subtotal,
            accountSummary: accountSummary,
          },
          () => {
            this.executeRequests();
          }
        );
      } else {
        this.setState({
          isPaymentProcessing: false,
        });
        this.props.showMessage("danger", "Invalid email address!");
      }
    } 
    else {
      this.setState(
        {
          subtotal: subtotal,
          accountSummary: accountSummary,
        },
        () => {
          this.executeRequests();
        }
      );
    }
  }

  showPaymentResult = () => {
    window.onbeforeunload = null;
    window.location.replace("/payment-result");
  };

  executeRequests = () => {
    localStorage.setItem("reload", "false");
    this.props
      .savePaymentData(this.state)
      .then((result) => {
        const { saveToPayEezyStatus, saveAdminReportsStatus } = result;
        if(result.saveToPayEezyStatus == false){
          this.setState({
            isPaymentProcessing: false,
          });
          let result = {
            data: {
              Transaction_Approved: "PayEezy Failed",
            },
          };
          localStorage.setItem(
            "accountSummary",
            JSON.stringify(this.state.accountSummary)
          );
          localStorage.setItem("paymentResult", JSON.stringify(result));
          return null
        }
        console.log(result.saveToPayEezyStatus)
        if (saveToPayEezyStatus.result.status == "False") {
          this.setState({
            isPaymentProcessing: false,
          });
          this.props.showMessage("danger", saveToPayEezyStatus.result.data);
        } else if (
          saveToPayEezyStatus === false ||
          saveAdminReportsStatus === false
        ) {
          let result = {
            data: {
              Transaction_Approved: "serverFailed",
            },
          };
          localStorage.setItem(
            "accountSummary",
            JSON.stringify(this.state.accountSummary)
          );
          localStorage.setItem("paymentResult", JSON.stringify(result));
          this.showPaymentResult();
        } else {
          localStorage.setItem(
            "accountSummary",
            JSON.stringify(this.props.dashboard.orderData.accountSummary)
          );
          localStorage.setItem(
            "paymentResult",
            JSON.stringify(saveToPayEezyStatus.result)
          );
          this.showPaymentResult();
        }
      })
      .catch((error) => {
        // alert('test123 '+error)
        let result = {
          data: {
            Transaction_Approved: "serverFailed",
          },
        };
        localStorage.setItem(
          "accountSummary",
          JSON.stringify(this.state.accountSummary)
        );
        localStorage.setItem("paymentResult", JSON.stringify(result));
        this.showPaymentResult();
      });
  };
  showCardType = (cardNumber) => {
    if (cardNumber.charAt(0) === "4") {
      return "card-visa-logo";
    } else if (cardNumber.charAt(0) === "6") {
      return "card-discover-logo";
    } else if (
      parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) > 50 &&
      parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) < 56
        ? true
        : false
    ) {
      return "card-master-logo";
    } else if (cardNumber === "") {
      return "";
    } else {
      return "card-undefined-logo";
    }
  };
  confirmationEmailHint = (e) => {
    this.props.showMessage(
      "",
      "Enter email address to which the receipt will be sent to, otherwise, leave it blank."
    );
  };
  render() {
    var fullName = this.state.userFullName;
    var firstName = fullName.substr(0, fullName.indexOf(" "));
    var lastName = fullName.substr(fullName.indexOf(" ") + 1);
    if (fullName.includes(",")) {
      firstName = fullName.substr(fullName.indexOf(",") + 1);
      lastName = fullName.substr(0, fullName.indexOf(","));
    }
    const userFullname = firstName + " " + lastName;
    return this.props.dashboard.orderData.subTotal === 0 ? (
      <React.Fragment>
        <Modal
          id="my-bills-modal"
          show={this.state.showErrMsg}
          onHide={() => {
            this.setState({ showErrMsg: false });
          }}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <center>
                <label>
                  <strong>
                    Please select an Account ID and enter an amount to be paid!
                  </strong>
                </label>
              </center>
              <br />
            </React.Fragment>
          </Modal.Body>
        </Modal>
        <div className="text-right-lg">
          <button
            type="submit"
            className="btn btn-primary regular-font-size font-bold"
            onClick={()=>{
              if(  this.props.dashboard.orderData.subTotal>=1 &&   this.props.dashboard.orderData.subTotal<=99999.99){
                this.showModal();           
              }
              else{
                this.props.showMessage("danger", "The paying subtotal should be between $1.00 to $99999.99");
              }

            }}

          >
            Check Out
          </button>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Modal
          id="cvv-hint"
          show={this.state.showHint}
          onHide={this.hideCvvHint.bind(this)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pl-0">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={process.env.PUBLIC_URL + "/images/cvv-hint.png"}
                    alt="cvv-hint"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="hint-content">
                    <h5>CVV Code</h5>
                    <p>
                      The CVV Code is a security code and is the last 3-digits
                      printed on the signature panel on the back of your credit
                      card.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          id="pay-now-modal"
          show={this.state.show}
          onHide={this.hideModal.bind(this)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {
              <React.Fragment>
                <section id="review-order">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="section-title">
                          <h3>Review Your Payment</h3>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2">
                        <p>
                          <strong>Account Number</strong>
                        </p>

                        {_.map(
                          this.props.dashboard.orderData.accountSummary,
                          (item, index) => {
                            return item.checked &&
                              parseFloat(item.amountToBePaid) > 0.0 ? (
                              //  + (<br>)
                              <React.Fragment>
                                <p>{item.accID}</p>
                                {/* <br/> */}
                              </React.Fragment>
                            ) : (
                              ""
                            );
                          }
                        )}
                      </div>
                      <div className="col-lg-3">
                        <p>
                          <strong>Service Location</strong>
                        </p>

                        {_.map(
                          this.props.dashboard.orderData.accountSummary,
                          (item, index) => {
                            return item.checked &&
                              parseFloat(item.amountToBePaid) > 0.0 ? (
                              //  + (<br>)
                              <React.Fragment>
                                <p>{item.serviceLocation.split(",")[0]}</p>
                                {/* <br/> */}
                              </React.Fragment>
                            ) : (
                              ""
                            );
                          }
                        )}
                      </div>
                      {userFullname.replace(/\s/g, "").length ? (
                        <div className="col-lg-4">
                          <p>
                            <strong>Account Name</strong>
                          </p>
                          <p>{userFullname}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pt-3">
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="payment-options">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="section-title">
                          <h3>Total Amount</h3>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <br />
                        <NumberFormat
                          value={parseFloat(
                            this.props.dashboard.orderData.subTotal
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <h5 className="sub-total-val">{value}</h5>
                          )}
                        />
                        {/* <h5>{"$ "+parseFloat(Math.round(this.props.dashboard.orderData.subTotal * 100) / 100).toFixed(2)}</h5> */}
                      </div>
                    </div>
                  </div>
                </section>
                <section id="credit-card-info">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="section-title">
                          <h3>Pay with Your Credit Card</h3>
                        </div>
                      </div>
                    </div>
                    <Form
                      autocomplete="off"
                      onSubmit={this.savePayment.bind(this)}
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                              <strong>Card Holder Name</strong>
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Control
                                type="text"
                                autocomplete="off"
                                name="cardHolderName"
                                onChange={this.inputOnChange.bind(this) } 
                                value={this.state.cardDetails.cardHolderName}
                                required
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                              <strong>Card Number</strong>
                            </Form.Label>
                            <React.Fragment>
                              <Col sm={8}>
                                <InputGroup>
                                  <NumberFormat
                                    aria-describedby={this.showCardType(
                                      this.state.cardDetails.cardNumber
                                    )}
                                    autocomplete="off"
                                    className="form-control"
                                    name="cardNumber"
                                    onChange={this.inputOnChange.bind(this)}
                                    value={this.state.cardDetails.cardNumber}
                                    required
                                    format="#### #### #### ####"
                                    mask="_"
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text
                                      id={this.showCardType(
                                        this.state.cardDetails.cardNumber
                                      )}
                                      style={{ padding: "0", border: "0" }}
                                    ></InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>
                            </React.Fragment>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                              <strong>Expiration Date</strong>
                            </Form.Label>
                            <Col sm={4}>
                              <DatePicker
                                dateFormat="MM/yyyy"
                                placeholderText="mm/yyyy"
                                name="expDate"
                                autocomplete="off"
                                onChange={this.handleExpDate}
                                showMonthYearPicker
                                selected={this.state.expDateForm}
                                required
                              />
                              {/* <Form.Control type="date" name="expDate" style={{padding: "5px"}} onChange={this.inputOnChange.bind(this)} value={this.state.cardDetails.expDate} required/> */}
                            </Col>
                            <Form.Label column sm={1}>
                              <strong>CVV</strong>
                            </Form.Label>
                            <Col sm={3}>
                              <InputGroup>
                                <FormControl
                                  type="text"
                                  autocomplete="off"
                                  name="cvv"
                                  onChange={this.inputOnChange.bind(this)}
                                  value={this.state.cardDetails.cvv}
                                  required
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text
                                    style={{ paddingRight: "0" }}
                                  >
                                    <i
                                      class="fas fa-info-circle"
                                      style={{
                                        fontSize: "25px",
                                        verticalAlign: "middle",
                                      }}
                                      onClick={this.showCvvHint.bind(this)}
                                    ></i>
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                              <Form.Label column sm={4}>
                                  <strong>Card Zip Code (Enter your 5-digit card zip code.)</strong>
                              </Form.Label>
                              <Col sm={4}>
                                  <Form.Control type="text" pattern= "[0-9]+"  maxLength={5} autocomplete="off" name="billingZipCode" onChange={this.inputOnChange.bind(this)} value={this.state.cardDetails.billingZipCode} required/>
                              </Col>
                              <Col sm={4}>
                               <label>Format: 12345</label>
                              </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                              <strong>Confirmation Email</strong>
                            </Form.Label>
                            <Col sm={8}>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  autocomplete="off"
                                  name="confirmationEmail"
                                  onChange={this.inputOnChange.bind(this)}
                                  value={
                                    this.state.cardDetails.confirmationEmail
                                  }
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text
                                    style={{ paddingRight: "0" }}
                                  >
                                    <i
                                      class="fas fa-info-circle"
                                      style={{
                                        fontSize: "25px",
                                        verticalAlign: "middle",
                                      }}
                                      onClick={this.confirmationEmailHint.bind(
                                        this
                                      )}
                                    ></i>
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </Col>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-lg-6">
                          <div
                            id="cancel"
                            className="col-lg-5 p-0"
                            style={{ display: "inline-block" }}
                          >
                            <button
                              className="btn btn-primary regular-font-size font-bold"
                              disabled={this.state.isPaymentProcessing}
                              onClick={this.hideModal.bind(this)}
                              style={{
                                backgroundColor: "#fff",
                                borderColor: "#cccccc",
                                height: "42px",
                                width: "190px",
                                color: "#333",
                              }}
                            >
                              Go Back
                            </button>
                          </div>
                          <div
                            id="save"
                            className="col-lg-7 p-0"
                            style={{ display: "inline-block" }}
                          >
                            <button
                              type="submit"
                              className="btn btn-primary regular-font-size font-bold"
                              disabled={this.state.isPaymentProcessing}
                            >
                              {this.state.isPaymentProcessing
                                ? "Please wait..."
                                : "Pay Now"}
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6"></div>
                      </div>
                    </Form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pt-3">
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </React.Fragment>
            }
          </Modal.Body>
        </Modal>
        <div className="text-right-lg">
          <button
            type="submit"
            className="btn btn-primary regular-font-size font-bold"
            onClick={()=>{
              console.log(  this.props.dashboard.orderData.subTotal);
              if(  this.props.dashboard.orderData.subTotal>=1 &&   this.props.dashboard.orderData.subTotal<=99999.99){
                this.showModal();           
              }
              else{
                this.props.showMessage("danger", "The paying subtotal should be between $1.00 to $99999.99");
              }

            }}
          >
            Check Out
          </button>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  saveOrderData,
  savePaymentData,
  validateVisaPayment,
  showMessage,
})(PayNowModal);
