import axios from "axios";
import {
  SAVE_ACCOUNT_ID,
  SAVE_PREMISE_ADDRESS,
  FETCH_USER_DETAILS,
  FETCH_USER_OLD_DETAILS,
  FETCH_EDIT_SECURITY_QUESTIONS,
  FETCH_COUNTRIES,
  FETCH_LATEST_BILL,
  FETCH_CONSUMPTION_DETAILS,
  FETCH_BILL_LIST,
  FETCH_SURVEY_LIST,
  SAVE_VIEW_BILL_DATA,
  SAVE_ORDER_DATA,
  FETCH_PAYMENT_HISTORY_LIST,
} from "./types";

import { SHOW_MESSAGE, HIDE_MESSAGE } from "./types";

import * as moment from "moment";

const dashboardBaseURL = process.env.REACT_APP_DASHBOARD_API_URL;
const loginSignupBaseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;
const paynowBaseURL = process.env.REACT_APP_PAYNOW_API_URL;
require("dotenv").config();

function fetchLatestPayment(accountId, tenderType) {
  return axios.post(
    dashboardBaseURL + "/api/v1/validate",
    {
      accountId: accountId,
      tenderType: tenderType,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

export const saveOrderData = (postData) => (dispatch) => {
  const orderData = {
    accountSummary: postData.accountSummary,
    subTotal: postData.subTotal,
    isHasInvalid: postData.isHasInvalid,
  };
  dispatch({
    type: SAVE_ORDER_DATA,
    payload: orderData,
  });
};
function saveToPayEezy(postData, payment_count) {
  return axios
    .post(
      paynowBaseURL + "/api/v1/payeezy",
      {
        amount: postData.subtotal,
        card_num: postData.cardDetails.cardNumber,
        exp_date: postData.cardDetails.validExpDate,
        card_holder: postData.cardDetails.cardHolderName,
        CAVV: postData.cardDetails.cvv,
        custome_ref: postData.username.replace(/[^a-zA-Z0-9]/g, ""),
        payment_count: payment_count,
        zip_code: postData.cardDetails.billingZipCode
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
}
function savePaymentToCCB(
  overallId,
  usedCC,
  recepitNum,
  accountId,
  amount,
  payEezyResult,
  indivId
) {
  return axios
    .post(
      paynowBaseURL + "/api/v1/make-payment",
      {
        accountId: accountId,
        amount: amount,
        method: "PG001",
        cardCharge: "0.00",
        paymentSource:
          usedCC === "visa"
            ? "CCV"
            : usedCC === "discover"
            ? "CCD"
            : usedCC === "master"
            ? "CCM"
            : "", //CCV if VISA, CCD for Discover, CCM for MasterCard
        receiptNum: recepitNum,
        overall_id: overallId,
        individual_id: indivId,
        authNum: payEezyResult.data.Authorization_Num
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
function savePaymentToMarketingDB(
  postData,
  accountId,
  overallId,
  amountToBePaid
) {
  if (!overallId || !accountId || !amountToBePaid || !postData.username) {
    return false;
  }
  return axios
    .post(
      paynowBaseURL + "/api/v1/save-payment",
      {
        overall_id: overallId,
        account_number: accountId,
        amount: amountToBePaid,
        paid_by: postData.username,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
}
function updatePaymentToMarketingDB(id, status, receiptNum) {
  return axios
    .post(
      paynowBaseURL + "/api/v1/update-payment",
      {
        indivId: id,
        status: status,
        receipt: receiptNum,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
}
function saveOverallPayment(postData) {
  if (!postData.username || !postData.subtotal) {
    return false;
  }
  return axios
    .post(
      paynowBaseURL + "/api/v1/save-overall-payment",
      {
        username: postData.username,
        // receipt: payEezyResult.data.Reference_No,
        // transaction_tag: payEezyResult.data.Transaction_Tag,
        // pay_eezy_seq_num: payEezyResult.data.SequenceNo,
        amount: postData.subtotal,
        // process_message: payEezyResult.data.EXact_Message,
        // bank_response: payEezyResult.data.Bank_Message,
        mode: postData.mode === "admin" ? "Phoned-In" : "Online",
        payment_source:
          postData.usedCC === "visa"
            ? "CCV"
            : postData.usedCC === "discover"
            ? "CCD"
            : postData.usedCC === "master"
            ? "CCM"
            : "Undefined",
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
}
function updateOverallPayment(id, payEezyResult, ccb_status) {
  return axios
    .post(
      paynowBaseURL + "/api/v1/update-overall-payment",
      {
        overAllId: id,
        receipt: payEezyResult.data.Reference_No,
        transaction_tag: payEezyResult.data.Transaction_Tag,
        pay_eezy_seq_num: payEezyResult.data.SequenceNo,
        auth_no: payEezyResult.data.Authorization_Num ?? "",
        process_message: payEezyResult.data.EXact_Message,
        bank_response: payEezyResult.data.Bank_Message,
        ccb_status: ccb_status,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
}
function insertTransLogs(arrLogs) {
  return axios
    .post(
      loginSignupBaseURL + "/api/v1/insert-transaction-logs",
      {
        transaction_logs: arrLogs,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
}
function sendPaymentStatEmail(
  postData,
  accountIdsList,
  payEezyResult,
  transDate
) {
  const auth_num =
    payEezyResult.data.Authorization_Num.length === 0
      ? ""
      : payEezyResult.data.Authorization_Num;
  const priceSplitter = (number) =>
    number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let today = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date =
    months[today.getMonth()] +
    " " +
    today.getDate() +
    " " +
    today.getFullYear();
  let time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  return axios
    .post(
      paynowBaseURL + "/api/v1/confirm-email",
      {
        accounts: accountIdsList,
        email: postData.cardDetails.confirmationEmail,
        receiptNum: payEezyResult.data.Reference_No,
        cardholderName: payEezyResult.data.CardHoldersName,
        confirmationNum: payEezyResult.data.Transaction_Tag,
        transacDate: date + " " + time,
        creditNum: payEezyResult.data.Card_Number,
        authorizationNum: auth_num,
        total_amount:
          " $ " +
          priceSplitter(
            parseFloat(Math.round(postData.subtotal * 100) / 100).toFixed(2)
          ) +
          " USD ",
        userFullName: postData.userFullName,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
export const savePaymentData = (postData) => (dispatch) => {
  /* ENABLE USER EXIT PAGE CONFIRMATION */
  window.onbeforeunload = function (event) {
    return true;
  };

  /* SHOW POPUP MESSAGE */
  const msgData = {
    type: "warning",
    message: "Your payment is processing. Please don't close this page.",
  };
  dispatch({
    type: SHOW_MESSAGE,
    payload: msgData,
  });

  /* SAVE PAYMENT BEGIN */
  let arrSavePaymentToCCBRequests = [],
    arrSaveToMarketingDBRequests = [],
    accountIdsList = [];

  const { accountSummary } = postData;

  //Change this to array of objects

  for (let count = 0; count < accountSummary.length; count++) {
    if (
      accountSummary[count].checked &&
      accountSummary[count].amountToBePaid > 0
    ) {
      // accountIdsList += (accountSummary[count].accID + ', ');
      accountIdsList.push({
        accountno: accountSummary[count].accID,
        service_location: accountSummary[count].serviceLocation.split(",")[0],
        amount: accountSummary[count].amountToBePaid,
      });
    }
  }

  // console.log("accountIdsListaccountIdsListaccountIdsListaccountIdsListaccountIdsList",accountIdsList)
  let apiResponses = {
    overallId: null,
    saveAdminReportsStatus: false,
    arrSaveIndividualTransactionStatus: false,
    saveToPayEezyStatus: false,
    emailResponse: false,
    arrSavePaymentToCCBResponse: false,
    updateAdminReportsStatus: false,
    arrUpdateIndividualTransactionStatus: false,
  };

  return new Promise(async (resolve, reject) => {
    try {
      //note: if api response is false it means 'API ERROR'

      const checkedAccounts = accountSummary
        .filter(({ checked }) => checked)
        .map((account) => account); //gets all checked accounts on frontend
      const validAccounts = checkedAccounts
        .filter(({ amountToBePaid }) => amountToBePaid > 0)
        .map((account) => account);

      //Save transaction log in Admin Control

      //overall
      const saveOverallPaymentResponse = await Promise.resolve(
        saveOverallPayment(postData)
      );
      if (saveOverallPaymentResponse == false) {
        window.onbeforeunload = function (event) {
          return null;
        };
        resolve(apiResponses);
      }
      apiResponses.overallId = saveOverallPaymentResponse.overallId;
      apiResponses.saveAdminReportsStatus = saveOverallPaymentResponse;
      //individual
      const arrSaveToMarketingDBRequests = validAccounts.map((item, index) =>
        savePaymentToMarketingDB(
          postData,
          item.accID,
          saveOverallPaymentResponse.overallId,
          item.amountToBePaid
        )
      );

      const arrSaveToMarketingDBResponse = await Promise.all(
        arrSaveToMarketingDBRequests
      );
      apiResponses.arrSaveIndividualTransactionStatus =
        arrSaveToMarketingDBResponse;

      //add individual id to valid accounts
      for (
        let count = 0;
        count < arrSaveToMarketingDBResponse.length;
        count++
      ) {
        for (let count1 = 0; count1 < validAccounts.length; count1++) {
          if (
            arrSaveToMarketingDBResponse[count].indivId.account_number ===
            validAccounts[count1].accID
          ) {
            validAccounts[count1].indivId =
              arrSaveToMarketingDBResponse[count].indivId.id;
            break;
          }
        }
      }
      // console.log('validasdasdadsadsada', validAccounts);
      //Send payment info to payeezy
      const paymentCountCode =
        validAccounts.length > 1 ? `MULTI-${validAccounts.length}` : "SINGLE";
      const saveToPayEezyResponse = await Promise.resolve(
        saveToPayEezy(postData, paymentCountCode)
      );
      apiResponses.saveToPayEezyStatus = saveToPayEezyResponse;

      const { result } = saveToPayEezyResponse;

      //Validating payeezy response
      if (saveToPayEezyResponse == false || result.status == "False") {
        const msgData = {
          type: "danger",
          message: "Sorry, your payment did not go through. Please try again later.",
        };
        dispatch({
          type: SHOW_MESSAGE,
          payload: msgData,
        });
        console.log('before beforeunload')

        const insertTransLogsResponse = await Promise.resolve(
          insertTransLogs(apiResponses)
        );
        if (insertTransLogsResponse != false) {
          console.log("apiResponses", apiResponses);
        }
        resolve(apiResponses);
      } else {
        //str = (saveToPayEezyResponse.data.Reference_No + "-" + (index+1)) receipt number generator
        let receiptNumbers = "";

        //old format
        // if(validAccounts.length > 1){
        //     receiptNumbers = validAccounts.map((item, index) => (result.data.Reference_No + "-" + (index+1)).substring((result.data.Reference_No + "-" + (index+1)).length-14, (result.data.Reference_No + "-" + (index+1)).length))
        // }else{
        //     receiptNumbers = [result.data.Reference_No];
        // }

        receiptNumbers = validAccounts.map(
          (item, index) =>
            `W-${result.data.Transaction_Tag}${
              validAccounts.length > 1 ? "-" + (index + 1) : ""
            }`
        );

        // if(validAccounts.length > 1){
        //     receiptNumbers = validAccounts.map((item, index) => moment(Date.now()).format('YYMMDD') + (Math.floor(Math.random()*90000) + 10000) + "-" + (index+1))
        // }else{
        //     receiptNumbers = [result.data.Reference_No];
        // }

        // console.log('receiptNumbersreceiptNumbersreceiptNumbersreceiptNumbers', receiptNumbers);

        //Execute update ccb
        if (result.data.Transaction_Approved === "true") {
          //Send email to customer for payment confirmation or payment declined notice
          const emailResponse = await Promise.resolve(
            sendPaymentStatEmail(postData, accountIdsList, result)
          );
          apiResponses.emailResponse = emailResponse;

          const arrSavePaymentToCCBRequests = validAccounts.map((item, index) =>
            savePaymentToCCB(
              saveOverallPaymentResponse.overallId,
              postData.usedCC,
              receiptNumbers[index],
              item.accID,
              item.amountToBePaid,
              result,
              item.indivId
            )
          );

          const arrSavePaymentToCCBResponse = await Promise.all(
            arrSavePaymentToCCBRequests
          );
          apiResponses.arrSavePaymentToCCBResponse =
            arrSavePaymentToCCBResponse;

          const getFailedReponses = arrSavePaymentToCCBResponse.filter(
            ({ result }) => result.status == "False"
          );
          const ccbStatus = getFailedReponses.length > 0 ? "0" : "1";
          //Execute update overall payment
          const arrUpdateOverallPaymentResponse = await Promise.resolve(
            updateOverallPayment(
              saveOverallPaymentResponse.overallId,
              result,
              ccbStatus
            )
          );
          apiResponses.updateAdminReportsStatus =
            arrUpdateOverallPaymentResponse;
        } else {
          //Execute update overall payment
          const arrUpdateOverallPaymentResponse = await Promise.resolve(
            updateOverallPayment(
              saveOverallPaymentResponse.overallId,
              result,
              "0"
            )
          );
          apiResponses.updateAdminReportsStatus =
            arrUpdateOverallPaymentResponse;
        }

        //Execute update individual payment
        const status =
          result.data.Transaction_Approved === "true" ? "PAID" : "DECLINED";
        const arrUpdateIndiPaymentRequests = arrSaveToMarketingDBResponse.map(
          (item, index) =>
            updatePaymentToMarketingDB(
              item.indivId.id,
              status,
              receiptNumbers[index]
            )
        );
        const arrUpdateIndiPaymentResponse = await Promise.all(
          arrUpdateIndiPaymentRequests
        );
        apiResponses.arrUpdateIndividualTransactionStatus =
          arrUpdateIndiPaymentResponse;

        window.onbeforeunload = function (event) {
          return null;
        };

        const insertTransLogsResponse = await Promise.resolve(
          insertTransLogs(apiResponses)
        );
        if (insertTransLogsResponse != false) {
          console.log("apiResponses", apiResponses);
        }
        resolve(apiResponses);
      }
    } catch (error) {
      window.onbeforeunload = function (event) {
        return null;
      };

      const insertTransLogsResponse = await Promise.resolve(
        insertTransLogs(apiResponses)
      );
      if (insertTransLogsResponse != false) {
        console.log("apiResponses", apiResponses);
      }
      resolve(apiResponses);
    }
  });
};
export const validateVisaPayment = (accountId, usedCC) => (dispatch) => {
  const tenderType =
    usedCC === "visa"
      ? "CCV"
      : usedCC === "discover"
      ? "CCD"
      : usedCC === "master"
      ? "CCM"
      : ""; //CCV if VISA, CCD for Discover, CCM for MasterCard
  let arrLatestPayment = [];
  for (let count = 0; count < 1; count++) {
    arrLatestPayment.push(fetchLatestPayment(accountId, tenderType));
  }
  return new Promise((resolve, reject) => {
    axios
      .all(arrLatestPayment)
      .then((response) => {
        const resLatestPayment = response[0];
        let arrResults = [];
        resolve(resLatestPayment);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
function formatDate(date) {
  return moment(date).format("MM/YYYY");
}
export const fetchMultipleLatestBill =
  (accountId, currentAccountSummary) => async (dispatch) => {
    try {
      let arrLatestBillRequests = [],
        arrLatestPayment = [];
      for (let count = 0; count < accountId.length; count++) {
        arrLatestBillRequests.push(fetchLatestBill(accountId[count][0]));
        // arrLatestPayment.push(fetchLatestPayment(accountId[count][0]))
      }
      const arrLatestBillResponse = await Promise.all(arrLatestBillRequests);
      // console.log("arrLatestBillResponsearrLatestBillResponsearrLatestBillResponsearrLatestBillResponse", arrLatestBillResponse);

      let accountSummary = [];
      for (let count = 0; count < arrLatestBillResponse.length; count++) {
        const billResponse = arrLatestBillResponse[count].data.result;
        let billDate = new Date(billResponse.date.billDate);
        let finalBillDate = moment(billDate).format("MM/DD/YYYY");
        let dueDate = new Date(billResponse.date.dueDate);
        let finaldueDate = moment(dueDate).format("MM/DD/YYYY");
        // const paymentResponse = paymentResult.data.result.data
        let isDueDate = "",
          arrearsTotal = 0;
        const arrears = billResponse.arrears.arrears;
        for (let count = 0; count < arrears.length; count++) {
          if (
            !arrears[count].Label.includes("new charges") &&
            arrears[count].Label != ""
          ) {
            arrearsTotal =
              arrearsTotal + parseFloat(arrears[count].ArrearsAmount);
          }
        }
        let isDueDateRed = false;
        if (arrearsTotal === 0) {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let dueDate1 = new Date(dueDate);
          dueDate1.setHours(0, 0, 0, 0);
          if (dueDate <= today) {
            isDueDateRed = true;
          } else if (dueDate > today) {
            isDueDateRed = false;
          }
        } else {
          finaldueDate = "Due Now";
          isDueDateRed = true;
        }

        accountSummary.push({
          checked: false,
          validAmountToBePaid: true,
          accID: accountId[count][0],
          serviceLocation: billResponse.arrears.details.PremiseInfo,
          billDate: finalBillDate,
          amount: billResponse.arrears.details.CurrentBalance,
          isDueDateRed: isDueDateRed,
          dueDate: finaldueDate,
          // amountToBePaid: parseFloat(billResponse.arrears.details.CurrentBalance) > 0 ? parseFloat(billResponse.arrears.details.CurrentBalance) : 0.00,
          amountToBePaid: "",
          className: accountId[count][2],
          alreadyPaid: 0,
          fullName: billResponse.firstName + " " + billResponse.lastName,
          arrears: billResponse.arrears,
          arrearsTotal: arrearsTotal,
          usedCC: "",
          isActive: accountId[count][3],
        });
        // console.log("accountSummaryaccountSummaryaccountSummaryaccountSummaryaccountSummary",accountSummary)
      }

      const orderData = {
        accountSummary: currentAccountSummary
          ? [...currentAccountSummary, ...accountSummary]
          : accountSummary,
        subTotal: 0,
      };

      dispatch({
        type: SAVE_ORDER_DATA,
        payload: orderData,
      });
      return true;
    } catch ($ex) {
      return $ex;
    }

    // return new Promise((resolve,reject) => {
    //     axios.all([arrLatestBillRequests, ]) //arrLatestPayment
    //     .then((response) => {

    //     })
    //     .then((orderData) => {
    //         dispatch({
    //             type:    SAVE_ORDER_DATA,
    //             payload: orderData
    //         })
    //         resolve(true)
    //     })
    //     .catch((error) => {
    //         reject(error)
    //     })
    // });
  };

export const fetchMultipleAddOpptyRequest =
  (accountId, personId) => (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .all([
          fetchUserDetails(personId),
          fetchLatestBill(accountId),
          fetchMonthlyBillConsumption(accountId),
        ]) //
        .then(
          axios.spread(
            (
              fetchUserDetailsResponse,
              fetchLatestBillResponse,
              fetchMonthlyBillConsumptionResponse
            ) => {
              //fetchMonthlyBillConsumptionResponse
              //for fetchUserDetailsResponse
              var userDetails = {};
              userDetails.fullName =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Name;
              userDetails.emailAddress =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.EmailID;
              let contactNumbers =
                fetchUserDetailsResponse.data.result.otherDetails
                  .contactNumbers;
              for (var count = 0; count < contactNumbers.length; count++) {
                if (
                  contactNumbers[count].PhoneType === "MOBILE" &&
                  (userDetails.mobilePhone === undefined ||
                    userDetails.mobilePhone === "")
                ) {
                  userDetails.mobilePhone = contactNumbers[count].PhoneNumber;
                  userDetails.mobilePhoneSeq = contactNumbers[count].Sequence;
                } else if (
                  contactNumbers[count].PhoneType === "HOME-PHONE" &&
                  (userDetails.homePhone === undefined ||
                    userDetails.homePhone === "")
                ) {
                  userDetails.homePhone = contactNumbers[count].PhoneNumber;
                  userDetails.homePhoneSeq = contactNumbers[count].Sequence;
                } else if (
                  contactNumbers[count].PhoneType === "BUSN-PHONE" &&
                  (userDetails.workPhone === undefined ||
                    userDetails.workPhone === "")
                ) {
                  userDetails.workPhone = contactNumbers[count].PhoneNumber;
                  userDetails.workPhoneSeq = contactNumbers[count].Sequence;
                }
              }
              userDetails.addressLine1 =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Address;
              userDetails.addressLine2 =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Address2;
              userDetails.city =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.City;
              userDetails.state =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.StateDescription;
              userDetails.stateInitials =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.State;
              userDetails.postal =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Postal;
              userDetails.country =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Description;
              dispatch({
                type: FETCH_USER_DETAILS,
                payload: userDetails,
              });
              //for fetchLatestBillResponse
              var billDate = new Date(
                fetchLatestBillResponse.data.result.date.billDate
              );
              var finalBillDate = formatDate(billDate);
              var dueDate = new Date(
                fetchLatestBillResponse.data.result.date.dueDate
              );
              var finalDueDate = formatDate(dueDate);
              var latestBill = {
                billDate: finalBillDate,
                totalAmount: fetchLatestBillResponse.data.result.amount,
                dueDate: finalDueDate,
              };
              dispatch({
                type: FETCH_LATEST_BILL,
                payload: latestBill,
              });
              const isHaveConsumptionChart =
                fetchMonthlyBillConsumptionResponse.data.result.status;
              if (isHaveConsumptionChart === "True") {
                const data =
                  fetchMonthlyBillConsumptionResponse.data.result.data;
                let consumptionDetails = {};
                let months = [];
                let amounts = [];
                let totalWaters = [];
                for (var count = 0; count < data.length; count++) {
                  var startDate = data[count][0].startReadDate.slice(5, 10);
                  var endDate = data[count][0].endReadDate.slice(5, 10);
                  var amount = data[count][0].billSegmentCurrentAmount;
                  var totalWater = Math.round(data[count][0].measuredQuantity);
                  months.push([
                    startDate.split("-")[0] + "/" + startDate.split("-")[1],
                    " - ",
                    endDate.split("-")[0] + "/" + endDate.split("-")[1],
                  ]);
                  amounts.push(amount > 0 ? amount : 0);
                  totalWaters.push(totalWater > 0 ? totalWater : 0);
                }
                consumptionDetails.months = months;
                consumptionDetails.amounts = amounts;
                consumptionDetails.totalWater = totalWaters;
                dispatch({
                  type: FETCH_CONSUMPTION_DETAILS,
                  payload: consumptionDetails,
                });
                const results = {
                  isHaveConsumptionChart: isHaveConsumptionChart,
                  dataFetched: true,
                };
                resolve(results);
              } else {
                const results = {
                  isHaveConsumptionChart: isHaveConsumptionChart,
                  dataFetched: true,
                };
                resolve(results);
              }
              const results = {
                isHaveConsumptionChart: isHaveConsumptionChart,
                dataFetched: true,
              };
              resolve(results);
            }
          )
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
export const saveAccountId = (accountId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: SAVE_ACCOUNT_ID,
      payload: accountId,
    });
    resolve(accountId);
  });
};

export const submitHelpAndSupport = (postData) => (dispatch) => {
  let personId;
  for (var count = 0; count < Object.keys(localStorage).length; count++) {
    if (Object.keys(localStorage)[count] !== "baseUrl") {
      if (Object.keys(localStorage)[count] === "personId") {
        personId = localStorage.getItem(Object.keys(localStorage)[count]);
      }
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/help-and-support",
        {
          cis_division: "GWA",
          accountId: postData.accountId,
          personId: personId,
          emailAddress: postData.emailAddress,
          fullName: postData.fullName,
          contactType: postData.contactType,
          message: postData.message,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data.result.status);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSequQuestions = () => (dispatch) => {
  return axios
    .get(loginSignupBaseURL + "/api/v1/get-security-questions", {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      dispatch({
        type: FETCH_EDIT_SECURITY_QUESTIONS,
        payload: response.data.result,
      });
    });
};

export const getCountry = () => (dispatch) => {
  return axios
    .get(dashboardBaseURL + "/api/v1/get-country", {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      dispatch({
        type: FETCH_COUNTRIES,
        payload: response.data.result,
      });
    });
};

export const fetchPaymentHistory = (accountId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/payment-history",
        {
          accountId: accountId,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        const billList = response.data.result;
        const billListTableData = [];
        for (var count = 0; count < billList.length; count++) {
          try {
            billListTableData.push({
              payment_date: billList[count].ArrearsDate,
              account_number: billList[count].Parent,
              billTotalAmount: billList[count].TotalAmount,
              transType: billList[count].FinancialTransactionType,
              // status: billList[count].status,
              // paid_by: billList[count].paid_by,
              // receipt_num: billList[count].receipt_num,
              // receiptTotalAmount: billList[count].receiptTotalAmount,
            });
          } catch (ex) {
            break;
          }
        }
        dispatch({
          type: FETCH_PAYMENT_HISTORY_LIST,
          payload: billListTableData,
        });
        resolve(billListTableData);
      })
      .catch((error) => {});
  });
};

export const fetchBillsList = (accountId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/my-bills-list",
        {
          accountId: accountId,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        const billList = response.data.result;
        const billListTableData = [];
        for (var count = 0; count < billList.length; count++) {
          try {
            var billDate = new Date(billList[count].ArrearsDate);
            var finalBillDate = formatDate(billDate);
            billListTableData.push({
              billID: billList[count].Parent,
              billDate: finalBillDate,
              totalAmount: billList[count].TotalAmount,
            });
          } catch (ex) {
            break;
          }
        }
        dispatch({
          type: FETCH_BILL_LIST,
          payload: billListTableData,
        });
        resolve(billListTableData);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function fetchMonthlyBillConsumption(accountId) {
  return axios.post(
    dashboardBaseURL + "/api/v1/user-consumption-chart",
    {
      accountId: accountId,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

function fetchLatestBill(accountId) {
  //checks if accountid is a valid 10 digit numbers only
  if (/^\d+$/.test(accountId)) {
    return axios.post(
      dashboardBaseURL + "/api/v1/user-latest-bill",
      {
        accountId: accountId,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }
}

function fetchUserDetails(personId) {
  return axios.post(
    dashboardBaseURL + "/api/v1/user-details",
    {
      personId: personId,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

export const updateUserPassword = (postData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/update-user-password",
        {
          personId: postData.personId,
          oldPassword: postData.oldPassword,
          password: postData.password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserDetails = (postData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/update-user-details",
        {
          personId: postData.personId,
          accountId: postData.accountId,
          emailAddress: postData.emailAddress,
          address1: postData.address1,
          address2: postData.address2,
          city: postData.city,
          postal: postData.postal,
          state: postData.stateInitials,
          stateDesc: postData.stateDesc,
          country: "USA",
          home: postData.home,
          mobile: postData.mobile,
          work: postData.work,
          oldDateEmail: postData.oldDateEmail,
          oldDateQuestion: postData.oldDateQuestion,
          oldDateAnswer: postData.oldDateAnswer,
          CharacteristicValue: postData.characteristicValue,
          Answer: postData.answer,
          sequenceHome: postData.homeSeq,
          sequenceMobile: postData.mobileSeq,
          sequenceWork: postData.workSeq,
          isDeletedHome: postData.isDeletedHome,
          isDeletedMobile: postData.isDeletedMobile,
          isDeletedWork: postData.isDeletedWork,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchOldUserDetails = (personId) => (dispatch) => {
  return axios
    .post(
      dashboardBaseURL + "/api/v1/user-details-edit-account",
      {
        personID: personId,
        division: "GWA",
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((response) => {
      const oldData = response.data.result.otherDetails;
      var userOldDetails = {};
      // userOldDetails.billAddressSource    = response.data.result.details.billAddressSource
      for (var count = 0; count < oldData.length; count++) {
        if (oldData[count].CharacteristicType === "CMSCAN") {
          userOldDetails.oldDateAnswer = oldData[count].EffectiveDate;
          userOldDetails.oldAnswerSecuQues =
            oldData[count].AdhocCharacteristicValue;
        } else if (oldData[count].CharacteristicType === "CMSCQUES") {
          userOldDetails.oldDateSecuQuestion = oldData[count].EffectiveDate;
          userOldDetails.oldCharValueSecuQues =
            oldData[count].CharacteristicValue;
        } else if (oldData[count].CharacteristicType === "CMOLDEML") {
          userOldDetails.oldDateEmailAdd = oldData[count].EffectiveDate;
        }
      }
      dispatch({
        type: FETCH_USER_OLD_DETAILS,
        payload: userOldDetails,
      });
    });
};

export const getListSurvey = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(dashboardBaseURL + "/api/v1/get-list-survey", {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        dispatch({
          type: FETCH_SURVEY_LIST,
          payload: response.data.result.surveyList,
        });
        resolve(response.data.result.surveyList);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const submitSurvey = (postData) => (dispatch) => {
  let fullName = postData.userInfo.fullName;
  if (!fullName.includes(",")) {
    fullName = fullName.split(" ")[0] + ", " + fullName.split(" ")[1];
  }
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api-v1/submit-survey",
        {
          personId: postData.userInfo.personId,
          username: postData.userInfo.username,
          fullName: fullName,
          surveyAns1: postData.surveyAnswers.answer1,
          surveyAns2: postData.surveyAnswers.answer2,
          surveyAns3: postData.surveyAnswers.answer3,
          surveyAns4: postData.surveyAnswers.answer4,
          surveyAns5: postData.surveyAnswers.answer5,
          surveyAns6: postData.surveyAnswers.answer6,
          surveyAns7: postData.surveyAnswers.answer7,
          surveyAns8: postData.surveyAnswers.answer8,
          surveyAns9: postData.surveyAnswers.answer9,
          surveyAns10: postData.surveyAnswers.answer10,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data.result.status);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const savePremiseAddress = (premiseAddress) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: SAVE_PREMISE_ADDRESS,
      payload: premiseAddress,
    });
    resolve(premiseAddress);
  });
};

export const getViewBillData = (billID) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/view-bill",
        {
          billId: billID,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        dispatch({
          type: SAVE_VIEW_BILL_DATA,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function doTheSearch(str, result) {
  //this function basically search a string on the payeezy receipt
  const ctr = JSON.stringify(result.data.CTR);
  if(process.env.REACT_APP_USE_OLD_PAYEEZI === 'true'){
    const ctrEscaped = ctr
      .replace(/\\n/g, "$\\n")
      .replace(/\\'/g, "$\\'")
      .replace(/\\"/g, '$\\"')
      .replace(/\\&/g, "$\\&")
      .replace(/\\r/g, "$\\r")
      .replace(/\\t/g, "$\\t")
      .replace(/\\b/g, "$\\b")
      .replace(/\\f/g, "$\\f");
    const index = ctrEscaped.search(str); //get the index of searched string
    let flagStart = false;
    let value = "";
    for (let count = index; count < ctrEscaped.length; count++) {
      if (!flagStart) {
        if (ctrEscaped.charAt(count) === ":") {
          flagStart = true;
        }
      } else {
        if (ctrEscaped.charAt(count) != "$") {
          value += ctrEscaped.charAt(count);
        } else {
          break;
        }
      }
    }
    return value;
  }else{
    const inputString = ctr; 
    const patterns = [
        /TRANS\. REF\. (\d+)/,
        /PurchaseACCT: ([^\s]+)/,
        /CARDHOLDER NAME : ([^\s]+)/,
        /REFERENCE #     : ([^\s]+)/,
        /AUTHOR\. #       : ([^\s]+)/,
        /CARD NUMBER\s+: ([^\s]+)/,
    ];
    
    const patternValues = [];
    
    patterns.forEach(pattern => {
        const matches = inputString.match(pattern);
        patternValues.push(matches && matches[1] ? matches[1] : 'Not found');
    });
    
    // Assign patternValues to variables
    const acct = patternValues[1];
    const cardHolder = patternValues[2].replace('CARD', '');
    const transRef = patternValues[0];
    const sequence = patternValues[3].replace('AUTHOR.', '');
    const authNum = patternValues[4].replace('TRANS.', '');
    const cardNumber = patternValues[5].replace('DATE/TIME', '');

    if(str === "TRANS. REF."){
      return transRef;
    }else if(str === "AUTHOR. #"){
      return authNum;
    }else if(str === "REFERENCE #"){
      return sequence;
    }
  }
  return "N/A";
}

export const searchString = (str, result) => (dispatch) => {
  let value = doTheSearch(str, result);
  return value;
};
